<template>
  <div>

<el-row :gutter="20">
  <el-col :span="12">
    <el-card class="box-card" shadow="hover">
      <div slot="header" class="clearfix">
        <span>当前收费信息</span>
        <el-button style="float: right;" size="mini" @click="addFormButton()">修改价格</el-button>
      </div>
        <el-descriptions
            title=""
            :column="3"
            v-if="now_charge_price"
        >
            <el-descriptions-item label="启用时间">{{
            now_charge_price.create_time
            }}</el-descriptions-item>
            <el-descriptions-item label="每小时价格">{{
            now_charge_price.hourstop_price
            }} 元</el-descriptions-item>
            <el-descriptions-item label="24小时封顶价格">{{
            now_charge_price.is24hour_capping_price
            }} 元</el-descriptions-item>
        </el-descriptions>
    </el-card>

    <el-card class="box-card" shadow="hover">
      <div slot="header" class="clearfix">
        <span>历史收费价格调整记录</span>
      </div>
            <template>
                <el-table
                :data="old_charge_price"
                style="width: 100%">
                <el-table-column
                    prop="create_time"
                    label="启用时间">
                </el-table-column>
                <el-table-column
                    prop="hourstop_price"
                    label="每小时价格">
                </el-table-column>
                <el-table-column
                    prop="is24hour_capping_price"
                    label="24小时封顶价格">
                </el-table-column>
                <el-table-column
                    prop="failure_time"
                    label="失效时间">
                </el-table-column>
                </el-table>
            </template>
    </el-card>
  </el-col>

  <el-col :span="12">

      <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
          <span>月保价格信息</span>
          <el-button style="float: right;" size="mini">修改价格</el-button>
        </div>
        <el-descriptions
            title=""
            :column="3"
            v-if="now_charge_price"
        >
            <el-descriptions-item label="设置时间">{{
            now_monthly_charge.create_time
            }}</el-descriptions-item>
            <el-descriptions-item label="月保价格">{{
            now_monthly_charge.price_number
            }} 元</el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
          <span>月保价格调整记录</span>
        </div>
            <el-table
            :data="old_monthly_charge"
            style="width: 100%">
            <el-table-column
                prop="create_time"
                label="启用时间">
            </el-table-column>
            <el-table-column
                prop="price_number"
                label="月保价格">
            </el-table-column>
            <el-table-column
                prop="failure_time"
                label="失效时间1">
            </el-table-column>
            </el-table>
      </el-card>

  </el-col>
</el-row>

<el-dialog title="修改价格" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="车库id" label-width="140px">
          <el-input
            v-model="formAdd.garage_id"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="每小时价格(分)" label-width="140px">
          <el-input v-model="formAdd.hourstop_price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="24小时最高价格(分)" label-width="140px">
          <el-input v-model="formAdd.is24hour_capping_price" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>

import {
  garage_price_get,garage_price_update
} from "@/api/manage/business/garage/garage.js";

export default {
  
  data() {
    return {
      garage_id:0,
      formAdd:{
        hourstop_price:null,
        is24hour_capping_price:null
      },
      dialogFormVisibleAdd: false,
      now_charge_price: {},
      old_charge_price: [],
      now_monthly_charge: {},
      old_monthly_charge: []
    };
  },
  created() {
      this.getChargePrice();
  },
  methods: {
    createFirstLevel() {
      garage_price_update(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          this.getChargePrice();
        } else {
          this.$message.info(res.msg);
        }
      });
      console.log(this.formAdd);
      this.dialogFormVisibleAdd = false;
    },
    addFormButton() {
      this.formAdd.garage_id = this.$parent.$route.query.garage_id;
      if (this.now_charge_price){
        this.formAdd.hourstop_price = this.now_charge_price.hourstop_price * 100
        this.formAdd.is24hour_capping_price = this.now_charge_price.is24hour_capping_price * 100
      }else{
        this.formAdd.hourstop_price = 0
        this.formAdd.is24hour_capping_price = 0
      }
      this.dialogFormVisibleAdd = true;
    },
    getChargePrice() {
      this.$http(
        garage_price_get({
          garage_id: this.$parent.$route.query.garage_id,
        }),
        (res) => {
          console.warn(res);
          if (res.code == 200) {
            
            this.now_charge_price = res.data.now_charge
            this.old_charge_price = res.data.old_charge
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
        }
      );
    },
    
  },
};
</script>

<style lang="scss" scoped>
  .box-card{
    margin-top: 20px;
  }
</style>
