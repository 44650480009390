<template>
    <div>
        <!-- 点击事件触发 -->
        <el-dialog title="编辑车库信息" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="车库id" label-width="120px">
                    <el-input
                        v-model="itemData.garage_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库名" label-width="120px">
                    <el-input
                        v-model="formEdit.info_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库电话" label-width="120px">
                    <el-input
                        v-model="formEdit.info_phone"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库简介" label-width="120px">
                    <el-input
                        v-model="formEdit.info_introduce"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库地址" label-width="120px">
                    <el-input
                        v-model="formEdit.info_address"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车库楼层" label-width="120px">
                    <el-input
                        v-model="formEdit.info_floor"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="车位数" label-width="120px">
                    <el-input
                        v-model="formEdit.space"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <infoHeader :content_name="'车库业务管理'" />

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ itemData.info_name }}</span>
                <el-button style="float: right" @click="oepnEdit()"
                    >修改车库基础信息</el-button
                >
            </div>
            <el-descriptions
                class="margin-top"
                :column="4"
                direction="vertical"
            >
                <el-descriptions-item label="车库名"
                    ><div class="descriptions_content">
                        {{ itemData.info_name }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库介绍" span="3"
                    ><div class="descriptions_content">
                        {{ itemData.info_introduce }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库ID"
                    ><div class="descriptions_content">
                        {{ itemData.garage_id }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库地址"
                    ><div class="descriptions_content">
                        {{ itemData.info_address }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="联系电话"
                    ><div class="descriptions_content">
                        {{ itemData.info_phone }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="单位状态"
                    ><div class="descriptions_content">
                        {{ itemData.company_status }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车位数"
                    ><div class="descriptions_content">
                        {{ itemData.space }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="楼层数"
                    ><div class="descriptions_content">
                        {{ itemData.info_floor }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="出入方案"
                    ><div class="descriptions_content">
                        {{ itemData.access_plan_id }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item
                    label="开始营业时间"
                    v-if="itemData.start_business_time"
                    ><div class="descriptions_content">
                        {{ itemData.start_business_time }}
                    </div></el-descriptions-item
                >
                <el-descriptions-item label="车库是否允许连接云端"
                    ><div class="descriptions_content">
                        {{ itemData.tcpclient_empower_status }}
                    </div></el-descriptions-item
                >
            </el-descriptions>
        </el-card>

        <el-descriptions
            title="设置车库是否允许连接云端(设置未授权或取消授权后，车库将无法在连接云端)"
            border
            class="descriptions"
        ></el-descriptions>
        <el-card
            shadow="never"
            style="margin-bottom: 40px"
            :body-style="{ padding: '20px 10px 0px 20px' }"
        >
            <el-form
                :inline="true"
                :model="tcpChangeForm"
                class="demo-form-inline"
            >
                <el-form-item label="当前连接状态">
                    <el-select
                        v-model="tcpChangeForm.tcpclient_empower_status_int"
                        placeholder="活动区域"
                    >
                        <el-option label="正常" :value="1"></el-option>
                        <el-option label="未授权" :value="2"></el-option>
                        <el-option label="被取消授权" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" @click="changeTcpStatus"
                        >确认更改</el-button
                    >
                </el-form-item>
            </el-form>
        </el-card>

        <el-descriptions
            title="设置单位服务状态(设为暂停对外服务后将无法扫码停车)"
            border
            class="descriptions"
        ></el-descriptions>
        <el-card
            shadow="never"
            style="margin-bottom: 40px"
            :body-style="{ padding: '20px 10px 0px 20px' }"
        >
            <el-form
                :inline="true"
                :model="companystatusChangeForm"
                class="demo-form-inline"
            >
                <el-form-item label="当前营业状态">
                    <el-select
                        v-model="companystatusChangeForm.company_status_int"
                        placeholder="活动区域"
                    >
                        <el-option label="营业准备中" :value="1"></el-option>
                        <el-option label="正常对外服务" :value="2"></el-option>
                        <el-option label="暂停对外服务" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" @click="changeBusinessStatus"
                        >确认更改</el-button
                    >
                </el-form-item>
            </el-form>
        </el-card>

        <el-tabs
            v-model="tabActiveName"
            type="card"
            @tab-click="tabHandleClick"
        >
            <el-tab-pane label="出入方案设置" name="access_plan">
                <accessplan
                    v-if="show_access_plan"
                    :garage_id="itemData.garage_id"
                />
            </el-tab-pane>
            <el-tab-pane label="车库收费设置" name="chargeprice">
                <chargeprice
                    v-if="show_charge_price"
                    :garage_id="itemData.garage_id"
                />
            </el-tab-pane>
            <el-tab-pane label="中控账号管理" name="control_account">
                <controlaccount
                    v-if="show_control_account"
                    :garage_id="itemData.garage_id"
                />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import chargeprice from './garageManageInfoPage/charge_price.vue';
import controlaccount from './garageManageInfoPage/control_account.vue';
import accessplan from './garageManageInfoPage/access_plan.vue';
import {
    garage_get,
    garage_edit,
    gerage_change_business_status,
    garage_change_tcpclient_empower_status
} from '@/api/manage/business/garage/garage.js';
export default {
    name: 'start',
    data() {
        return {
            garage_id: this.$route.query.garage_id,
            itemData: {},
            show_access_plan: true,
            show_charge_price: false,
            show_control_account: false,
            tabActiveName: 'access_plan',
            dialogFormVisibleEdit: false,
            formEdit: {},
            tcpChangeForm: { tcpclient_empower_status_int: null },
            companystatusChangeForm: { company_status_int: null }
        };
    },
    components: {
        chargeprice,
        controlaccount,
        accessplan
    },
    created() {
        this.getGarageData(this.$route.query.garage_id);
    },
    mounted() {},
    methods: {
        getGarageData(garage_id) {
            garage_get({
                garage_id: garage_id
            }).then(res => {
                if (res.code == 200) {
                    this.itemData = res.data;
                    this.tcpChangeForm.tcpclient_empower_status_int =
                        res.data.tcpclient_empower_status_int;
                    this.companystatusChangeForm.company_status_int =
                        res.data.company_status_int;
                }
            });
        },
        tabHandleClick(tab, event) {
            this.show_charge_price = false;
            this.show_control_account = false;
            console.log(tab.paneName, event);
            if (tab.paneName == 'chargeprice') {
                this.show_charge_price = true;
            }
            if (tab.paneName == 'control_account') {
                this.show_control_account = true;
            }
        },
        oepnEdit() {
            this.formEdit = {
                garage_id: this.itemData.garage_id,
                info_name: this.itemData.info_name,
                info_phone: this.itemData.info_phone,
                info_address: this.itemData.info_address,
                info_introduce: this.itemData.info_introduce,
                space: this.itemData.space,
                info_floor: this.itemData.info_floor
            };

            this.dialogFormVisibleEdit = true;
        },
        updateFirstLevel() {
            garage_edit(this.formEdit).then(res => {
                if (res.code == 200) {
                    this.getGarageData(this.$route.query.garage_id);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        changeTcpStatus() {
            garage_change_tcpclient_empower_status({
                tcpclient_empower_status:
                    this.tcpChangeForm.tcpclient_empower_status_int,
                garage_id: this.garage_id
            }).then(res => {
                if (res.code == 200) {
                    this.getGarageData(this.garage_id);
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        changeBusinessStatus() {
            gerage_change_business_status({
                company_status: this.companystatusChangeForm.company_status_int,
                garage_id: this.garage_id
            }).then(res => {
                if (res.code == 200) {
                    this.getGarageData(this.garage_id);
                } else {
                    this.$message.info(res.msg);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.box-card {
    margin-bottom: 40px;
}
.descriptions_content {
    margin-bottom: 25px;
}
.clearfix {
    // padding-bottom: 10px;
    line-height: 40px;
}
</style>
