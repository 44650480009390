<template>
  <div>

    <el-dialog
    title="创建新的出入口方案"
    :visible.sync="createNewPlanDialog"
    width="40%"
    :before-close="createNewHandleClose">

        <el-form :model="createNewPlaform" label-width="80px">
            <el-form-item label="方案名">
                <el-input v-model="createNewPlaform.plan_name"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="createNewHandleClose">取 消</el-button>
            <el-button type="primary" @click="sendCreateNewPlan">确 定</el-button>
        </div>

    </el-dialog>


    <el-dialog
    title="方案添加出入口关联"
    :visible.sync="planIndexEntranceDialog"
    width="40%"
    :before-close="closePlanIndexEntranceDialog">

        <el-form :model="editPlaformData.newEntranceIndexFrom" label-width="80px">
            <el-form-item label="出入口">
                <el-select v-model="editPlaformData.newEntranceIndexFrom.entrance_id" placeholder="选择出入口">
                <el-option
                    v-for="item in editPlaformData.entranceList"
                    :key="item.id"
                    :label="item.entry_name"
                    :value="item.id"
                >
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="出入类型">
                <el-select v-model="editPlaformData.newEntranceIndexFrom.inptype" placeholder="出入类型">
                <el-option
                    key="input"
                    label="入口"
                    :value="2"
                >
                </el-option>
                <el-option
                    key="output"
                    label="出口"
                    :value="1"
                >
                </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="closePlanIndexEntranceDialog">取 消</el-button>
            <el-button type="primary" @click="sendPlanIndexEntrance">确 定</el-button>
        </div>

    </el-dialog>


    <el-dialog
    title="修改出入方案设置"
    :visible.sync="planEditDialog"
    width="60%"
    :before-close="planEditDialogHandleClose">

    <el-card shadow="hover" :body-style="{ padding: '20px 20px 0px 20px' }">
        <el-form :inline="true" :model="editPlaformData" >
            <el-form-item label="出入口方案名">
                <el-input v-model="editPlaformData.plan_name"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="sendPlanEdit">保存方案名</el-button>
            </el-form-item>
            <el-form-item >
                <el-button type="primary" @click="oepnPlanIndexEntranceDialog">添加出入口配置项</el-button>
            </el-form-item>
        </el-form>
    </el-card>

    <el-row :gutter="30" style="margin-top: 20px">
    <el-col :span="12">

           <el-divider content-position="left">方案入口配置</el-divider>
        <el-table
            :data="editPlaformData.inputTableData"
            border
            style="width: 100%">
            <el-table-column
            label="出入口名"
            prop="entry_name">
            </el-table-column>
            <el-table-column
            label="所属分区"
            prop="partition">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                    <el-button
                    @click.native.prevent="sendPlanIndexDelete(scope.row)"
                    type="text"
                    size="small">
                    移除
                    </el-button>
                </template>
                </el-table-column>
        </el-table>

        <!-- <el-card shadow="hover">
        <div slot="header" class="clearfix">
            <span>方案入口配置</span>
        </div>
        <template>
        </template>
        </el-card> -->

    </el-col>
    <el-col :span="12">
<el-divider content-position="left">方案出口配置</el-divider>
        <el-table
            :data="editPlaformData.outputTableData"
            border
            style="width: 100%">
            <el-table-column
            label="出入口名"
            prop="entry_name">
            </el-table-column>
            <el-table-column
            label="所属分区"
            prop="partition">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                    <el-button
                    @click.native.prevent="sendPlanIndexDelete(scope.row)"
                    type="text"
                    size="small">
                    移除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- <el-card shadow="hover">
        <div slot="header" class="clearfix">
            <span>方案出口配置</span>
        </div>

        </el-card> -->
    </el-col>
    </el-row>

        <div slot="footer" class="dialog-footer">
            <el-button @click="planEditDialogHandleClose">关闭</el-button>
        </div>

    </el-dialog>


    <el-descriptions title="设置，创建或编辑出入方案适用于不同时段停取车峰值场景 (3分钟内只能切换一次, 禁止频繁切换)" border class="descriptions">

        <template slot="extra">
        <el-button type="primary" size="small" @click="oepnCreateNewPlanDialog">创建新的方案</el-button>
        </template>

    </el-descriptions>

    <el-row>
    <el-col :span="24">
        <el-card shadow="hover" :body-style="{ padding: '20px 20px 0px 20px' }">

        
            <el-form :inline="true">
                    <el-form-item v-if="nowPlanSet">当前使用的出入口方案: <span style="color: #00a2ff;font-weight: 600;">{{nowPlanSet.plan_name}}</span></el-form-item>
                    <el-form-item v-if="nowPlanSet">方案配置入口: <span style="color: #00a2ff;font-weight: 600;">{{nowPlanSet.input}}</span></el-form-item>
                    <el-form-item v-if="nowPlanSet">方案配置出口: <span style="color: #00a2ff;font-weight: 600;">{{nowPlanSet.output}}</span></el-form-item>
                    <el-form-item v-if="nowPlanSet == null"><span>当前使用的出入口方案：尚未设置</span></el-form-item>
            </el-form>

        </el-card>
    </el-col>
    </el-row>

    <el-row :gutter="20">
    <el-col :span="6" v-for="(o, index) in planData" :key="o+index">
        <el-card :body-style="{ padding: '0px' }" shadow="hover">
        <div class="plan_img">
            <span>{{ o.plan_name }}</span>
        </div>
            <div style="padding: 15px;padding-bottom: 0px;">
            <el-form>
                <el-form-item label="该方案入口配置">
            <span>{{ o.input }}</span>
          </el-form-item>
          <el-form-item label="该方案出口配置">
            <span>{{ o.output }}</span>
          </el-form-item>
</el-form>
            <el-form :inline="true">
                    <el-form-item><el-button class="button" size="small" @click="setPlanForGarage(o)">选用该方案</el-button></el-form-item>
                    <el-form-item><el-button class="button" size="small" @click="oepnEditPlanDialog(o)">修改设置</el-button></el-form-item>
                    <el-form-item><el-button class="button" size="small" @click="deletePlan(o)">删除方案</el-button></el-form-item>
            </el-form>
            </div>
        </el-card>
    </el-col>
    </el-row>

  </div>
</template>

<script>

import {
  garage_accessplan_updeate, access_plan_create, access_plan_query, access_plan_get, access_plan_update, access_plan_delete, access_plan_get_entrance_list, access_plan_query_index_entrance, access_plan_query_delete_entrance
} from "@/api/manage/business/garage/garage.js";

export default {
  data() {
    return {
        nowPlanSet:{},
        createNewPlanDialog: false,
        planEditDialog: false,
        planIndexEntranceDialog: false,
        createNewPlaform:{
            garage_id: this.$parent.$route.query.garage_id,
            plan_name: null
        },
        planData:[
        ],
        editPlaformData:{
            id: null,
            plan_name: null,
            inputTableData:[],
            outputTableData:[],
            entranceList: [],
            newEntranceIndexFrom:{
                entrance_id: null,
                inptype: null
            }
        }
    };
  },
  created() {
    this.getPlanData()
  },
  methods: {
    getPlanData(){
        access_plan_query({
            garage_id: this.$parent.$route.query.garage_id
        }).then((res) => {
            if (res.code == 200) {
                this.planData = res.data.data
                this.nowPlanSet = res.data.nowplan
            }
        });
    },
    setPlanForGarage(item){
        garage_accessplan_updeate({
            accessplan_id: item.id,
            garage_id: this.$parent.$route.query.garage_id
        }).then((res) => {
            if (res.code == 200) {
                this.getPlanData()
            }
        });
    },
    deletePlan(item){
        access_plan_delete({
            accessplan_id: item.id,
            garage_id: this.$parent.$route.query.garage_id
        }).then((res) => {
            if (res.code == 200) {
                this.getPlanData()
            }
        });
    },
    oepnCreateNewPlanDialog(){
        this.createNewPlanDialog = true
    },
    sendCreateNewPlan(){
        access_plan_create(this.createNewPlaform).then((res) => {
            if (res.code == 200) {
                this.itemData = res.data
                this.getPlanData()
                this.createNewPlanDialog = false
            }
        });
    },
    createNewHandleClose(){
        this.createNewPlanDialog = false
    },
    oepnEditPlanDialog(item){
        this.editPlaformData.id = item.id
        this.editPlaformData.plan_name = item.plan_name
        this.planEditDialog = true
        access_plan_get({
            accessplan_id: this.editPlaformData.id
        }).then((res) => {
            if (res.code == 200) {
                this.editPlaformData.inputTableData = res.data.inputd
                this.editPlaformData.outputTableData = res.data.outputd
            }
        });
    },
    planEditDialogHandleClose(){
        this.planEditDialog = false
    },
    sendPlanEdit(){
        access_plan_update({
            garage_id: this.$parent.$route.query.garage_id,
            accessplan_id: this.editPlaformData.id,
            plan_name: this.editPlaformData.plan_name
        }).then((res) => {
            if (res.code == 200) {
                this.getPlanData()
                this.planEditDialog = false
            }
        });
    },
    oepnPlanIndexEntranceDialog(){
        access_plan_get_entrance_list({
            garage_id: this.$parent.$route.query.garage_id
        }).then((res) => {
            if (res.code == 200) {
                this.editPlaformData.entranceList = res.data
            }
        });
        this.planIndexEntranceDialog = true
    },
    closePlanIndexEntranceDialog(){
        this.planIndexEntranceDialog = false
    },
    sendPlanIndexEntrance(){
        this.planIndexEntranceDialog = false
        access_plan_query_index_entrance({
            entrance_id: this.editPlaformData.newEntranceIndexFrom.entrance_id,
            accessplan_id: this.editPlaformData.id,
            inptype: this.editPlaformData.newEntranceIndexFrom.inptype
        }).then((res) => {
            if (res.code == 200) {
                access_plan_get({
                    accessplan_id: this.editPlaformData.id
                }).then((res) => {
                    if (res.code == 200) {
                        this.editPlaformData.inputTableData = res.data.inputd
                        this.editPlaformData.outputTableData = res.data.outputd
                    }
                });
            }
        });

    },
    sendPlanIndexDelete(scope){
        console.log(scope)
        access_plan_query_delete_entrance({
            plan_index_id: scope.id,
            accessplan_id: this.editPlaformData.id
        }).then((res) => {
            if (res.code == 200) {
                this.editPlaformData.inputTableData = res.data.inputd
                this.editPlaformData.outputTableData = res.data.outputd
            }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.plan_img{
    letter-spacing:10px;
    text-align: center;
    line-height: 160px;
    font-size: 24px;
    background-color: #00a2ff;
    color: #ffffff;
}
</style>